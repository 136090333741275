<template>
  <div style="height: 15rem; width: 100%;display: flex; flex-direction:column;">
    <vue3-chart-js v-if="renderComponent" :id="lineChart.id"
        :type="lineChart.type"
        :data="lineChart.data"
        width="100%" ></vue3-chart-js>
  </div>
</template>
  
<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
  name: 'App',
  data(){
    return{
        shouldRender: false,
        renderComponent: true,
    }
  },
  props:{
    labels: Array,
    dataSet1: Array,
    dataSet2: Array,
    cats: Array,
  },
  components: {
    Vue3ChartJs,
  },
  watch:{
    dataSet1(){
        if(this.dataSet1.length > 0){
            this.lineChart.data.labels = this.labels
            this.lineChart.data.datasets[0].data = this.dataSet1
            this.lineChart.data.datasets[1].data = this.dataSet2
            this.forceRerender()
        }
        //console.log(this.$parent)
    },
},
  setup (props) {
    const lineChart = {
      id: 'line',
      type: 'line',
      options: {
        plugins: {
            legend: {
                display: true
            }
         }
      },
      data: {
        labels: props.labels,
        datasets: [{
          label: props.cats[0],
          data: props.dataSet1,
          backgroundColor: 'rgba(251, 86, 7, 0.6)',
          borderColor: 'rgba(251, 86, 7, 1)',
          borderWidth: 1
        },{
          label: props.cats[1],
          data: props.dataSet2,
          backgroundColor: 'rgba(33, 158, 188, 0.6)',
          borderColor: 'rgba(33, 158, 188, 1)',
          borderWidth: 1
        }]
      }
    }
    return {
      lineChart,
    }
    
  },methods:{
    forceRerender() {
        // Removing my-component from the DOM
        this.renderComponent = false;
        this.$nextTick(() => {
          // Adding the component back in
          this.renderComponent = true;
        });
      }
    }
}
</script>

<style scoped lang="scss">

</style>
