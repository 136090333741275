<template>
        <div class="parent">
          <div class="date">
          </div>
          <div class="chart">
            <div class="sales">
              <div>
                <h3>Total Daily Sales</h3>
                <h2>{{"$" + daysTotal }}</h2>
              </div>
            </div>
            <div class="payment layout">
              <table class="breakdown">
                <h3>Payment Breakdown</h3>
                <tr v-for="payment, index in paymentLabels" :key="index">
                  <td>{{ payment }}</td>
                  <td>{{"$" + paymentData[index].toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{{"$" + daysPayments.toFixed(2) }}</td>
                </tr>
              </table>        
            </div>

            <div class="layout">
              <table class="breakdown">
                <h3>Category Breakdown</h3>
                <tr v-for="sale, index in salesLabels" :key="index">
                  <td>{{ sale }}</td>
                  <td>{{"$" + salesData[index].toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>{{"$" + daysPayments.toFixed(2) }}</td>
                </tr>
              </table>
              <Doughnut :labels="salesLabels" :data="salesData"/>
            </div>
            <div class="line-graph">
              <LineChartParent />
            </div>
          </div>
          
        </div>
</template>

<script>
  import {api} from "@/helpers/api";
  import Doughnut from '@/components/Totals/Doughnut.vue'
  import createdMixin from "@/components/_mixins/createdMixin";
  import LineChartParent from "@/components/Totals/LineChartParent.vue";
//  import LineChartBonus from "@/components/Totals/LineChartBonus.vue";
//   import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  
  export default {
    name: 'Totals',
    mixins: [createdMixin],
    components: { Doughnut, LineChartParent },
    props:[],
    data() {
      return {
        daysTotal:0,
        daysPayments:null,
        paymentLabels: [],
        paymentData: [],
        salesLabels: [],
        salesData: [],
        dayDate: new Date(),
        
      }
    },
    setup() {
    //     // In case of a range picker, you'll receive [Date, Date]
    //     const format = (dayDate) => {
    //     const day = dayDate.getDate();
    //     const month = dayDate.getMonth() + 1;
    //     const year = dayDate.getFullYear();
        
    //     return `Selected date is ${day}/${month}/${year}`;
    //     }
    //     const endDate= () => {
    //         return new Date()
    //     }
    //     return {
    //         format,
    //         endDate
    //     }
    },
    async mounted(){
      this.getPayments();
      //console.log("here",this.apiData)
    },
    watch:{
        dayDate(){
            this.getPayments();
        }
    },
    methods:{
      checkBagels() {
        if (window.localStorage.getItem("company") === "bagels") {
          return true;
        } else {
          return false;
        }
      },

      async apiCall(type, startDate, endDate, group, group_level){
        return await api.get(`/api/reports/${type}/${localStorage.getItem("company")}_${localStorage.getItem("site")}?group=${group}&group_level=${group_level}&start_key=["${startDate}"]&end_key=["${endDate}"]`)
      },
      async getSales(startDate, endDate,){
        // const totals = await this.apiCall('sales', startDate, endDate, true, 0)
        // totals.rows.length > 0 ? this.daysTotal = totals.rows[0].value : this.daysTotal = 0;

        let catSales = await this.apiCall('sales', startDate, endDate, true, 2)
        //console.log(catSales)   
        this.daysTotal = catSales.rows.reduce((acc, sale) => {
          return acc + sale.value;
        }, 0);
        
        catSales = this.reducerByKey(catSales)
        //console.log(catSales)
        this.salesLabels = Object.keys(catSales);
        this.salesLabels = this.salesLabels.map((label) => {
          return label.charAt(0).toUpperCase() + label.slice(1);
        });
        this.salesData = Object.values(catSales);
      },
      async getPayments(){
        let start =new Date(this.dayDate.setHours(0,0,0));
        let end = new Date(this.dayDate.setHours(23,59,59));

        let payments = await this.apiCall('payments',start.toISOString().substring(0,13),end.toISOString().substring(0,13), true, 2)

        this.daysPayments = payments.rows.reduce((acc, payment) => {
          return acc + payment.value;
        }, 0);
        //console.log(this.daysPayments)
        
        payments.rows.sort((a,b)=> {
            if (a.key[1] < b.key[1]) return -1;
            if (a.key[1] > b.key[1]) return 1;
            return 0;
        })
        
        this.getSales(start.toISOString().substring(0,13),end.toISOString().substring(0,13))
        
        if(this.daysPayments === 0){
            //console.log('no payments')
            this.paymentLabels = ['No Payments'];
            this.paymentData = [0];
            return;
        }
        this.todaysPayments = this.reducerByKey(payments);
        this.paymentLabels = Object.keys(this.todaysPayments);
        this.paymentLabels = this.paymentLabels.map((label) => {
          return label.charAt(0).toUpperCase() + label.slice(1);
        });
        this.paymentData = Object.values(this.todaysPayments);
        },
      reducerByKey(reduceArray){
        const reducedArray = reduceArray.rows.reduce((acc, payment) => {
          const value = acc[payment.key[1]] ?? 0;
          return {...acc, [payment.key[1]]: value + payment.value};
        }, {});
        return reducedArray;
      },
  
    }
  }
  </script>
  
  <style scoped lang="scss">

  .parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .seperator{
        border-left: 3px solid rgb(154, 158, 161);
        height: 400px;
    }
    .chart{
      width: 80vw;
      display: grid;
  grid-template-areas:
  "sales line-graph"
  "payment payment"
  "breakdown breakdown";
      flex-direction: column;
      //align-items: center;
      justify-content: normal;
      gap: 10px;

      .line-graph{
        grid-area:line-graph;
        background-color: var(--secondary-colour);
  border-radius: 1rem;
      }
      .layout{ 
        grid-area: breakdown;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
        background-color: var(--secondary-colour, 0.25);
        border-radius: 1rem;
        .breakdown{
          width: 400px;
          tr{
            td{
              width: 100px;
            }
          }
          tr:last-child{
              font-weight: bold;
              text-decoration: underline;
          }
        }
      }
      .payment{
        grid-area: payment;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
        gap: 10px;
        justify-content: center;
        background-color: var(--secondary-colour);
        border-radius: 1rem;
        h3{
            padding: 0;
            margin: 0 auto;
        }

        h2{
            padding: 0;
            margin: 0 auto;
            font-weight: bold;
        }
      }

      .sales{
        grid-area: sales;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        justify-content: center;
        background-color: var(--secondary-colour);
        border-radius: 1rem;
        height: 100%;
        h3{
            padding: 0;
            margin: 0 auto;
        }

        h2{
            padding: 0;
            margin: 0 auto;
            font-weight: bold;
        }
      }
      .bonus:hover {
	transform: rotateY( 180deg );
	transition: transform 0.5s;
      }
      .bonus{
	grid-area: bonus;
	transition: transform 1s;
	transform-style: preserve-3d;
        align-items: center;
        justify-content: center;
        background-color: var(--secondary-colour);
        border-radius: 1rem;
        margin-bottom: 2rem;
        height: 60rem;
	.front, .back {
            backface-visibility: hidden;
            width: 100%;
            text-align: center;
            position: absolute;
        }
	.back {
            transform: rotateY( 180deg );
	}
          h3{
             vertical-align: middle;
        }

        h2{
            padding: 0;
            margin: 0 auto;
            font-weight: bold;
        }
  
    }
    
    }
    .date{
        width: 100%;
    }

    .totals{
        display: flex;
        flex-direction: row;
        gap: 10px;

    
    }

    
  }
  
  </style>
